import { Component } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: 'brightside-web-financial-core-kyc-verification-in-progress',
  templateUrl: './kyc-verification-in-progress.component.html',
  styleUrls: ['./kyc-verification-in-progress.component.scss']
})
export class FinancialCoreKYCVerificationInProgressComponent {

  constructor(private _router: Router) { }
  
  redirectHome(): void {
    this._router.navigate(['home']);
  }
}