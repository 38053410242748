import { Component } from '@angular/core';
import { MaintenanceService } from '../maintenance.service';

@Component({
  selector: 'brightside-web-maintenance-page',
  templateUrl: './maintenance-page.component.html',
  styleUrls: ['./maintenance-page.component.scss'],
})
export class MaintenancePageComponent {
  constructor(
    private maintenanceService:MaintenanceService
  ) { }
}
