/**
 * These are still part of the desktop application BUT need to be moved
 * over to the shared display app ASAP
 */

import {
  CONTROL_ADDRESS_CITY as SHARED_CONTROL_ADDRESS_CITY,
  CONTROL_ADDRESS_STATE as SHARED_CONTROL_ADDRESS_STATE,
  CONTROL_ADDRESS_ZIP as SHARED_CONTROL_ADDRESS_ZIP,
  CONTROL_DOB as SHARED_CONTROL_DOB,
  CONTROL_FNAME as SHARED_CONTROL_FNAME,
  CONTROL_LNAME as SHARED_CONTROL_LNAME,
  CONTROL_MNAME as SHARED_CONTROL_MNAME,
  CONTROL_SSN as SHARED_CONTROL_SSN,
  CONTROL_STREET_ADDRESS_LINE_ONE as SHARED_CONTROL_STREET_ADDRESS_LINE_ONE,
  CONTROL_STREET_ADDRESS_LINE_TWO as SHARED_CONTROL_STREET_ADDRESS_LINE_TWO,
} from '@brightside-web/shared/desktop';

const SHARED_CONTROL_LEGAL_FIRST_NAME = {
  ...SHARED_CONTROL_FNAME,
  inputOptions: { ...SHARED_CONTROL_FNAME.inputOptions, placeholder: 'Legal first name' },
};

const SHARED_CONTROL_LEGAL_MIDDLE_NAME = {
  ...SHARED_CONTROL_MNAME,
  inputOptions: { ...SHARED_CONTROL_MNAME.inputOptions, placeholder: 'Legal middle name(s)' },
};

const SHARED_CONTROL_LEGAL_LAST_NAME = {
  ...SHARED_CONTROL_LNAME,
  inputOptions: { ...SHARED_CONTROL_LNAME.inputOptions, placeholder: 'Legal last name (Suffix)' },
};

export {
  SHARED_CONTROL_ADDRESS_CITY,
  SHARED_CONTROL_ADDRESS_STATE,
  SHARED_CONTROL_ADDRESS_ZIP,
  SHARED_CONTROL_DOB,
  SHARED_CONTROL_FNAME,
  SHARED_CONTROL_LEGAL_FIRST_NAME,
  SHARED_CONTROL_LEGAL_LAST_NAME,
  SHARED_CONTROL_LEGAL_MIDDLE_NAME,
  SHARED_CONTROL_LNAME,
  SHARED_CONTROL_MNAME,
  SHARED_CONTROL_SSN,
  SHARED_CONTROL_STREET_ADDRESS_LINE_ONE,
  SHARED_CONTROL_STREET_ADDRESS_LINE_TWO,
};
