import { Injectable, Injector } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Route, Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthService, MobileStateService } from '@brightside-web/desktop/data-access/shared';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { BsAuthService } from '@brightside-web/desktop/data-access/core-services';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanLoad, CanActivate {

  constructor(
    private authService: AuthService,
    private router:Router,
    private _bsAuthService: BsAuthService,
    private _msSvc: MobileStateService
  ) {}
  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree  {

    return this.authService.hasCurrentUser().pipe(
      switchMap(hasUser => {
        if (!hasUser) {
          this.authService.redirectUrl = window.location.pathname;
          if (window.location.href.indexOf('signin=true') > -1) {
            return of(this.router.parseUrl('/sign_in'));
          } else {
            return of(this.router.parseUrl('/sign_up'));
          }
        }
        return this.checkMaintenance(route.path);
      })
    );
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.authService.hasCurrentUser().pipe(
      switchMap(hasUser => {
        if (!hasUser) {
          this.authService.redirectUrl = state.url;
          if (window.location.href.indexOf('signin=true') > -1) {
            return of(this.router.parseUrl('/sign_in'));
          } else {
            return of(this.router.parseUrl('/sign_up'));
          }
        }
        return this.checkMaintenance(route.routeConfig?.path);
      })
    );
  }

  private checkMaintenance(routePath: string | undefined): Observable<boolean> {
    return this._bsAuthService.fetchUserAttributes().pipe(
      switchMap(attributes => {
        if (attributes.guid) {
          return this._msSvc.get().pipe(
            map(ms=> {
              const inMaintenance = ms.redirect === '/maintenance';
              if (inMaintenance && routePath !== '/maintenance') {
                this.router.navigate(['/maintenance']);
                return false;
              }
              return true
            }),
            catchError((error:unknown) => of(true))
          );
        }
        return of(true);
      })
    )
  }

}
