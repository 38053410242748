import { Environment } from '@brightside-web/micro/core/environment';

import { FirebaseApp } from 'firebase/app';
import { RemoteConfig, getRemoteConfig, fetchAndActivate } from 'firebase/remote-config';

export class FirebaseRemoteConfigure {
  public instance: RemoteConfig;

  constructor(private defaultConfig?: { [key: string]: string | number | boolean }, private defaultSupportNumber: string = '') {}

  public initRemoteConfig(firebaseApp: FirebaseApp, environment: Environment) {
    this.instance = getRemoteConfig(firebaseApp);
    this.instance.settings.minimumFetchIntervalMillis = environment.production ? 300000 : 15000;
  }

  public fetchAndActivate() {
    fetchAndActivate(this.instance).then((didActivate: boolean) => {
      console.log(`debug didActivate ${didActivate}`);
    });
  }
}
