import { Injectable } from '@angular/core';
import {from, Observable, of, Subject} from "rxjs";
import {
  BsApiService, BsChatService, FeatureFlagService, GenericResponseCode,
  GenericResponseResult,
  SimpleGlobalModalService
} from '@brightside-web/desktop/data-access/core-services';
import {FinancialAccountPayload, FinancialAccountTypes} from "@brightside-web/desktop/feature/financial-accounts-core";
import {catchError, tap} from "rxjs/operators";
import { Intercom } from 'ng-intercom';
import {Router} from "@angular/router";
import { KnownFeatureFlags } from '@brightside-web/desktop/data-access/shared';

export enum AccountCreationSteps {
  INTRO = 'intro',
  VERIFICATION = 'verification',
  CONSENTS = 'consents',
  CREATE = 'create',
  COMPLETED = 'completed',
  KYC = 'kyc',
  AUTOSAVE = 'autosave',
  KYC_VERIFICATION = 'kyc-verification'
}

export enum AccountCreationResponses {
  ACCOUNT_ALREADY_EXISTS = 'ACCOUNT_ALREADY_EXISTS',
  USER_CREATION_FAILED = 'USER_CREATION_FAILED',
  ACCOUNT_CREATION_FAILED = 'ACCOUNT_CREATION_FAILED',
  RETRY = 'RETRY',
  USER_NOT_FOUND = 'USER_NOT_FOUND',
  KYC_DENIED = 'KYC_DENIED',
  KYC_MANUAL_REVIEW = 'KYC_MANUAL_REVIEW',
  KYC_AUTOMATED_REVIEW = 'KYC_AUTOMATED_REVIEW',
  KYC_RESUBMIT_ADDRESS = 'KYC_RESUBMIT_ADDRESS',
  KYC_RESUBMIT_IMAGE = 'KYC_RESUBMIT_IMAGE',
  KYC_VERIFIED = 'KYC_VERIFIED',
  PRODUCT_PENDING = 'PRODUCT_PENDING'
}

export enum KYCResponseOrigin {
  ACCOUNT_CREATION,
  KYC_VERIFICATION
}

export type AccountCreationResponse = AccountCreationResponses | GenericResponseCode;

@Injectable()
export class FinancialAccountsCreationService {

  // current step is purposely session only
  currentStep : AccountCreationSteps = AccountCreationSteps.INTRO;
  accountType: FinancialAccountTypes;

  creationStepsOrder : AccountCreationSteps[] = [
    AccountCreationSteps.INTRO,
    AccountCreationSteps.VERIFICATION,
    AccountCreationSteps.CONSENTS,
    AccountCreationSteps.CREATE,
    AccountCreationSteps.KYC,
    AccountCreationSteps.COMPLETED
  ];

  kycResponseOrigin: KYCResponseOrigin;
  accountCreationResponse: AccountCreationResponses;

  apiName = 'api-mobile';

  private _accountCreatedResp = new Subject<AccountCreationResponse>();
  public readonly accountCreatedResp: Observable<AccountCreationResponse> = this._accountCreatedResp.asObservable();

  constructor(
    private bsApi: BsApiService,
    private modalSvc: SimpleGlobalModalService,
    private intercom: Intercom,
    private router: Router,
    private _bsChatService: BsChatService,
    private _featureFlagSvc: FeatureFlagService,
    ) {}

  createAccount<T>(accountType: FinancialAccountTypes): Observable<(FinancialAccountPayload<T>) & GenericResponseResult> {
    this.accountType = accountType;
    return this.bsApi.post<(FinancialAccountPayload<T>) & GenericResponseResult>(
      this.apiName,
      `/accounts/${accountType}`,
    ).pipe(
      tap(resp => {
        this.kycResponseOrigin = KYCResponseOrigin.ACCOUNT_CREATION;
        this.checkResultCode(resp.result.code);
      }),
      catchError((err: Error) => {
        const msg = err.message.toLowerCase();
        this.sharedErrors(msg);
        return from([]);
      })
    );
  }

  advanceStep(step: AccountCreationSteps) {
    const stepIndex = this.creationStepsOrder.indexOf(step);
    if (stepIndex !== this.creationStepsOrder.length - 1) {
      this.currentStep = this.creationStepsOrder[stepIndex + 1];
    }
  }

  retractStep(step: AccountCreationSteps) {
    const stepIndex = this.creationStepsOrder.indexOf(step);
    if (stepIndex !== 0) {
      this.currentStep = this.creationStepsOrder[stepIndex - 1];
    }
  }

  isStepBeforeCurrent(step: AccountCreationSteps) {
    return this.creationStepsOrder.indexOf(step) <= this.creationStepsOrder.indexOf(this.currentStep);
  }

  kycCheck(documentUuid: string) : Observable<GenericResponseResult>{
    const product = this.accountType;
    const payload = this.accountCreationResponse === AccountCreationResponses.KYC_MANUAL_REVIEW ?
      { documentUuid, product } : { documentUuid }
    return this.bsApi.post(
      this.apiName,
      '/accounts/kyc/verification',
      payload
    ).pipe(
      tap(resp => {
        this.kycResponseOrigin = KYCResponseOrigin.KYC_VERIFICATION;
        this.checkResultCode(resp.result.code);
      }),
      catchError((err: Error) => {
        const msg = err.message.toLowerCase();
        this.sharedErrors(msg);
        return from([]);
      })
    );
  }

  sharedErrors(msg: string) {
    switch (msg) {
      case 'helix_creation_failed':
        this.modalSvc.createModal(
          {
            title: 'error_general_title'.toUpperCase(),
            contentKey: 'error_general_desc'.toUpperCase(),
            displayCloseButton: true,
            eventName: "helix_creation_failed",
            isError: true,
            size: "medium",
            ctaKey: 'RETRY',
            ctaAction: () => {
              this._accountCreatedResp.next(AccountCreationResponses.RETRY);
            },
            closeRoute: ['home']
          }
        );
        break;
      case 'kyc_denied':
        this.modalSvc.createModal(
          {
            title: 'KYC_DENIED_TITLE',
            contentKey: 'KYC_DENIED_DESC',
            headerImagePath: './assets/hourglass.png',
            displayCloseButton: true,
            eventName: "kyc_denied",
            isError: false,
            size: "medium",
            closeRoute: ['home']
          }
        );
        break;
      case 'kyc_manual_review':
        this._featureFlagSvc.getFlag<boolean>(KnownFeatureFlags.ENABLESOCUREMANUALREVIEW).pipe(
          tap(socureManualReview => {
            if (socureManualReview) {
              this.currentStep = AccountCreationSteps.KYC;
              this.accountCreationResponse = AccountCreationResponses.KYC_MANUAL_REVIEW;
              if (this.kycResponseOrigin === KYCResponseOrigin.ACCOUNT_CREATION) {
                this.router.navigate(['accounts', this.accountType, 'creation', AccountCreationSteps.KYC]);
              } else {
                this.router.navigate(['accounts', this.accountType, 'creation', AccountCreationSteps.KYC_VERIFICATION]);
              }
            } else {
              this.modalSvc.createModal(
                {
                  title: 'KYC_MANUAL_REVIEW_TITLE',
                  contentKey: 'KYC_MANUAL_REVIEW_DESC',
                  headerImagePath: './assets/warning-exclamation.png',
                  displayCloseButton: true,
                  eventName: "kyc_manual_review",
                  isError: false,
                  size: "medium",
                  ctaKey: 'KYC_MANUAL_REVIEW_CTA1',
                  ctaAction: () => {
                    this._bsChatService.showChat();
                    this.router.navigate(['home']);
                    this.modalSvc.clearModal();
                  },
                  closeAction: () => {
                    this.router.navigate(['home']);
                    this.modalSvc.clearModal();
                  }
                }
              );
            }
          })
        ).subscribe();
        break;
      case 'kyc_resubmit_address':
        this.modalSvc.createModal(
          {
            title: 'KYC_RESUBMIT_ADDRESS_TITLE',
            contentKey: 'KYC_RESUBMIT_ADDRESS_DESC',
            headerImagePath: './assets/warning-exclamation.png',
            displayCloseButton: true,
            eventName: "kyc_resubmit_address",
            isError: false,
            size: "medium",
            ctaKey: 'BUTTON_CONTINUE',
            ctaAction: () => {
              this.router.navigate(['accounts', this.accountType, 'creation', AccountCreationSteps.VERIFICATION]);
            },
            closeRoute: ['home']
          }
        );
        break;
      case 'kyc_automated_review':
        this.currentStep = AccountCreationSteps.KYC;
        this.accountCreationResponse = AccountCreationResponses.KYC_AUTOMATED_REVIEW;
        if (this.kycResponseOrigin === KYCResponseOrigin.ACCOUNT_CREATION) {
          this.router.navigate(['accounts', this.accountType, 'creation', AccountCreationSteps.KYC]);
        } else {
          this.router.navigate(['accounts', this.accountType, 'creation', AccountCreationSteps.KYC_VERIFICATION]);
        }
        break;
      default:
        this.modalSvc.createModal(
          {
            title: 'error_general_title'.toUpperCase(),
            contentKey: 'error_general_desc'.toUpperCase(),
            displayCloseButton: true,
            eventName: "network_error",
            isError: true,
            size: "medium",
            ctaKey: 'TRY_AGAIN',
            ctaAction: () => {
              this._accountCreatedResp.next(AccountCreationResponses.RETRY);
            },
            closeRoute: ['home']
          }
        );
        break;
    }
  }

  checkResultCode(code: string) {
    switch (code) {
      case AccountCreationResponses.ACCOUNT_CREATION_FAILED:
      case AccountCreationResponses.USER_CREATION_FAILED:
        throw new Error('helix_creation_failed');
        break;
      case AccountCreationResponses.KYC_DENIED:
        throw new Error(AccountCreationResponses.KYC_DENIED);
        break;
      case AccountCreationResponses.KYC_MANUAL_REVIEW:
        throw new Error(AccountCreationResponses.KYC_MANUAL_REVIEW);
        break;
      case AccountCreationResponses.KYC_VERIFIED:
        this.currentStep = AccountCreationSteps.COMPLETED;
        this.router.navigate(['accounts', this.accountType, 'dashboard']);
        break;
      case AccountCreationResponses.KYC_RESUBMIT_ADDRESS:
        throw new Error(AccountCreationResponses.KYC_RESUBMIT_ADDRESS);
        break;
      case AccountCreationResponses.KYC_AUTOMATED_REVIEW:
        throw new Error(AccountCreationResponses.KYC_AUTOMATED_REVIEW);
        break;
      case AccountCreationResponses.PRODUCT_PENDING:
        this.router.navigate(['accounts', this.accountType, 'creation', AccountCreationSteps.KYC_VERIFICATION]);
        break;
      default:
        break;
    }

  }

  setAccountType(accountType: FinancialAccountTypes) {
    if (!this.accountType) this.accountType = accountType;
  }
}
