<div class="modal-sheet">
  <div class="modal-container" [ngClass]="[sizeClass, heightClass]">
    <div class="close-modal" *ngIf="showCloseButton" (click)="_closeModal($event)">
      <bw-icon iconName="close" size="24"></bw-icon>
    </div>
    <div class="modal-inner-container">
      <div class="header-container">
        <ng-content select="[title-medium]"></ng-content>
      </div>
      <div class="body-container" [ngClass]="{ 'no-footer': footerContainer.children.length === 0, 'full-content-width': fullContentWidth }">
        <ng-content select=".body"></ng-content>
      </div>
      <div #footerContainer class="footer-container">
        <ng-content select=".footer"></ng-content>
      </div>
    </div>
  </div>
</div>
