import { inject, Injectable } from '@angular/core';
import { FirebaseService, MobileStateService } from '@brightside-web/desktop/data-access/shared';
import { BsAuthService, BsHubService } from '@brightside-web/desktop/data-access/core-services';
import { tap } from 'rxjs';

export enum MaintenanceProductType {
  External = 'external',
  Savings = 'savings',
  Spending = 'spending',
  Web = 'web',
  Contest = 'contest',
  Mpap = 'mpap',
  HelpingHandsConsents = 'helpinghandsconsents',
  HelpingHands = 'helpinghands',
  LoanDashboard = 'loandashboard',
  Loans = 'loans',
  Credit = 'credit',
  FindMoneyIntro = 'findmoneyintro',
  FindMoney = 'findmoney',
  TermsUpdate = 'termsupdate',
  AutoSave = 'autosave',
  Survey = 'survey',
  Chat = 'chat',
  ChatHistory = 'chathistory'
}


@Injectable({
  providedIn: 'root'
})
export class MaintenanceService {

  _bsHubService: BsHubService = inject(BsHubService);

  private _rules: {rules:{ regex: string; product: string; inMaintenance: boolean }[]};
  private _productMaintenanceMap: { [product: string]: boolean } = {};
  private _appInMaintenance = false;

  constructor(
    private _analytics: FirebaseService,
    private _msSvc: MobileStateService,
    private _bsAuthService: BsAuthService,
  ) {
    this._analytics.ensureInitialized().then(() => {
      this.getRules();
    });
    this._bsHubService.listen('bsAuth', (data)=>{
      switch (data.payload.event) {
        case 'signedIn':
          this.getMobileStateRedirect();
          break;
      }
    });
  }

  private getRules() {
    try {
      this._rules = JSON.parse(this._analytics.getValueAsString('domainMappingPaths'));
      this.reduceRulesToMaintenanceMap();
    } catch (e) {
      setTimeout(()=>{
        // keep trying until we get the rules
        this.getRules();
      },150);
    }
  }

  private reduceRulesToMaintenanceMap() {
    this._rules.rules.forEach(rule => {
      const { product, inMaintenance } = rule;
      // Set to true if any rule for the product has inMaintenance: true
      if (inMaintenance) {
        this._productMaintenanceMap[product] = true;
      } else if (!(product in this._productMaintenanceMap)) {
        // Only set to false if no previous true flag was set
        this._productMaintenanceMap[product] = false;
      }
    });
  }

  public isProductInMaintenance(productType: string): boolean {
    return this._productMaintenanceMap[productType] || false;
  }

  public isAppInMaintenance():boolean {
    return this._appInMaintenance;
  }

  getMobileStateRedirect() {
    this._bsAuthService.fetchUserAttributes().pipe(
      tap(attributes => {
        if (attributes.guid) {
          this._msSvc.get().subscribe({
            next: ms => {
              if (ms.redirect === '/maintenance') {
                this._appInMaintenance = true;
              }
            }
          })
        }
      })
    ).subscribe();
  }
}
