import { Amplify } from 'aws-amplify';

import { Environment } from '@brightside-web/micro/core/environment';

import { InjectedInformation } from '@brightside-web/micro/core/authorization';


declare let Injected: InjectedInformation;
declare global {
  interface Window {
    Injected: InjectedInformation;
  }
}

export class AmplifyConfigure {
  constructor() {}

  get oneTimeUseUUID() {
    let d = new Date().getTime(); //Timestamp
    let d2 = (typeof performance !== 'undefined' && performance.now && performance.now() * 1000) || 0; //Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      let r = Math.random() * 16; //random number between 0 and 16
      if (d > 0) {
        //Use timestamp until depleted
        // eslint-disable-next-line no-bitwise
        r = (d + r) % 16 | 0;
        d = Math.floor(d / 16);
      } else {
        //Use microseconds since page-load if supported
        // eslint-disable-next-line no-bitwise
        r = (d2 + r) % 16 | 0;
        d2 = Math.floor(d2 / 16);
      }
      // eslint-disable-next-line no-bitwise
      return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
    });
  }


  public setUpConfig(environment: Environment) {
    //sso domain for hosted ui
    const domain = environment.hostedDomain;
    let location = window.location.origin;
    if (!location.endsWith('/')) {
      location = `${location}/`;
    }
    // allow both formats.
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // tslint:disable-next-line:no-bitwise
    // eslint-disable-next-line no-bitwise
    const authenticationFlowType = 'ALLOW_REFRESH_TOKEN_AUTH' | 'ALLOW_USERNAME_PASSWORD';

    const translateDomain = (domainPart: string) => `${domainPart}.auth.us-west-2.amazoncognito.com`;

    Amplify.configure({
      Auth: {
        Cognito: {
          userPoolId: environment.awsmobile.aws_user_pools_id,
          userPoolClientId: environment.awsmobile.aws_user_pools_web_client_id,
          signUpVerificationMethod: 'code',
          loginWith: {
            oauth: {
              domain: translateDomain(domain),
              scopes: ['phone', 'email', 'openid', 'aws.cognito.signin.user.admin'],
              redirectSignIn: [location],
              redirectSignOut: [location],
              responseType: 'token', // or 'token', note that REFRESH token will only be generated when the responseType is code
            },
          }
        }
      },
      API: {
        REST: {
          'api-mobile' : {
            endpoint: environment.awsmobile.endpoints['api-mobile'].endpoint,
            region: 'us-west-2',
          },
          'api-mobile-noauth' : {
            endpoint: environment.awsmobile.endpoints['api-mobile-noauth'].endpoint,
            region: 'us-west-2'
          },
          'cdn-mobile-noauth' : {
            endpoint: environment.awsmobile.endpoints['cdn-mobile-noauth'].endpoint,
            region: 'us-west-2',
          },
        },
      },
    });
    // Amplify.configure({
    //   Auth: {
    //     region: environment.awsmobile.aws_cognito_region,
    //     userPoolId: environment.awsmobile.aws_user_pools_id,
    //     userPoolWebClientId: environment.awsmobile.aws_user_pools_web_client_id,
    //     authenticationFlowType,
    //     storage: window.sessionStorage,
    //     // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    //     mandatorySignIn: false,
    //
    //     // SSO config.
    //     oauth: {
    //       domain: translateDomain(domain),
    //       scope: ['phone', 'email', 'openid', 'aws.cognito.signin.user.admin'],
    //       redirectSignIn: location,
    //       redirectSignOut: location,
    //       responseType: 'token', // or 'token', note that REFRESH token will only be generated when the responseType is code
    //     },
    //
    //     // OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
    //     clientMetadata: {
    //       myCustomKey: 'myCustomValue',
    //       platform: 'desktop',
    //     },
    //   },
    //   API: {
    //     endpoints: [
    //       {
    //         ...environment.awsmobile.endpoints['api-mobile'],
    //         custom_header: async () => {
    //           return {
    //             // I would prefer that we could always get the token from the Auth.currentSession but that doesn't seem to work from mobile
    //             // secondly we should look at the Injected.token.idToken since that is the 'newer' format
    //             Authorization: `${window.Injected?.token?.accessToken || (await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    //             ...this.getStandardHeaders(),
    //           };
    //         },
    //       },
    //       {
    //         ...environment.awsmobile.endpoints['api-mobile-noauth'],
    //         custom_header: async () => {
    //           return {
    //             ...this.getStandardHeaders(),
    //           };
    //         },
    //       },
    //       {
    //         ...environment.awsmobile.endpoints['cdn-mobile-noauth'],
    //       },
    //     ],
    //   },
    //   Cache: {
    //     storage: window.sessionStorage,
    //   },
    // });
  }
}
