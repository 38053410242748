import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FinancialAccountCreationWaitingComponent } from './financial-account-creation-waiting/financial-account-creation-waiting.component';
import { FinancialAccountCreationErrorComponent } from './financial-account-creation-error/financial-account-creation-error.component';
import { FinancialAccountsCoreMainComponent } from './financial-accounts-core-main/financial-accounts-core-main.component';
import { FinancialAccountsTransferFundsComponent } from './financial-accounts-transfer-funds/financial-accounts-transfer-funds.component';
import { FinancialAccountsCreationService } from './services/financial-accounts-creation.service';
import { FinancialAccountsCoreService } from './services/financial-accounts-core.service';
import { FinancialAccountsExternalAccountComponent } from './financial-accounts-external-account/financial-accounts-external-account.component';
import { SharedDesktopModule } from '@brightside-web/shared/desktop';
import { BrightsideUiModule } from '@brightside/brightside-ui';
import { TranslateModule } from '@ngx-translate/core';
import { FinancialCoreKYCComponent } from './socure/socure.component';
import { MicroUiMoleculeModule } from 'libs/micro-ui/molecule/src/lib/micro-ui-molecule.module';
import { SocureService } from './services/socure.service';
import { FinancialCoreKYCVerificationInProgressComponent } from './kyc-verification-in-progress/kyc-verification-in-progress.component';

const ROUTES: Routes = [
  {
    path: '',
    component: FinancialAccountsCoreMainComponent,
    children: [
      {
        path: 'create/:type',
        component: FinancialAccountCreationWaitingComponent,
      },
    ]
  },
];

@NgModule({
  imports: [
    CommonModule,
    BrightsideUiModule,
    RouterModule.forChild(ROUTES),
    MicroUiMoleculeModule,
    SharedDesktopModule,
    TranslateModule,
  ],
  declarations: [
    FinancialCoreKYCComponent,
    FinancialCoreKYCVerificationInProgressComponent,
    FinancialAccountCreationWaitingComponent,
    FinancialAccountCreationErrorComponent,
    FinancialAccountsCoreMainComponent,
    FinancialAccountsTransferFundsComponent,
    FinancialAccountsExternalAccountComponent,
  ],
  providers: [SocureService, FinancialAccountsCreationService, FinancialAccountsCoreService],
})
export class DesktopFeatureFinancialAccountsCoreModule {}
