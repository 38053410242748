export enum UiMoleculeIcon {
  ADD = 'add',
  ALARM = 'alarm',
  ALERT = 'alert',
  ANTENNA = 'antenna',
  ARROW_BACK = 'arrow_back',
  ARROW_DOWN = 'arrow_down',
  ARROW_FORWARD = 'arrow_forward',
  ARROW_UP = 'arrow_up',
  AUTOSAVE = 'autosave',
  BIKE = 'bike',
  BILL = 'bill',
  CALENDAR = 'calendar',
  CALENDAR_CHECK = 'calendar_check',
  CAMERA = 'camera',
  CAR = 'car',
  CAR_2 = 'car_2',
  CHAT = 'chat',
  CHAT_DOUBLE = 'chat_double',
  CHECK = 'check',
  CHECK_BADGE = 'check_badge',
  CHECKMARK = 'checkmark',
  CIRCLE_ARROW = 'circle_arrow',
  CLOCK = 'clock',
  COG = 'cog',
  CREDIT_CARD = 'credit_card',
  DOCUMENT = 'document',
  ELLIPSES = 'ellipses',
  EMAIL = 'email',
  GAUGE = 'gauge',
  GRAD_CAP = 'grad_cap',
  GRAPH_POSITIVE = 'graph_positive',
  GROUP = 'group',
  HEADSET = 'headset',
  HELP_RING = 'help_ring',
  HOME = 'home',
  INFORMATION = 'information',
  INVITE = 'invite',
  KEY = 'key',
  LIGHTBULB = 'lightbulb',
  LIGHTNING_BOLT = 'lightning_bolt',
  LINK = 'link',
  LOCATION = 'location',
  LOCATION_ADD = 'location_add',
  LOCK = 'lock',
  MEGAPHONE = 'megaphone',
  MINUS = 'minus',
  MONEY_BAG = 'money_bag',
  MONEY_BAG_IN_HAND = 'money_bag_in_hand',
  NOTIFICATIONS = 'notifications',
  NOTIFICATIONS_OFF = 'notifications_off',
  PAPER_AIRPLANE = 'paper_airplane',
  PEN = 'pen',
  PIG = 'pig',
  PIN = 'pin',
  PLANE = 'plane',
  PLANE_2 = 'plane_2',
  PLANT_POT = 'plant_pot',
  REFRESH = 'refresh',
  RESTART = 'restart',
  ROCKET = 'rocket',
  SAFE = 'safe',
  SEARCH = 'search',
  SECURE = 'secure',
  SETTINGS = 'settings',
  SHOPPING_CART = 'shopping_cart',
  SIGN_OUT = 'sign_out',
  STAR_SHIELD = 'star_shield',
  SUITCASE = 'suitcase',
  TRASH = 'trash',
  UNLOCKED = 'unlocked',
  USER = 'user',
  USER_CHAT_BUBBLE = 'user_chat_bubble',
  UPLOAD = 'upload',
  UPLOAD_CLOUD = 'upload_cloud',
  VIEW = 'view',
  WALKIN = 'walking',
}
