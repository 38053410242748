import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Router, Route, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MaintenanceService } from './maintenance.service';

@Injectable({
  providedIn: 'root'
})
export class MaintenanceRedirectGuard implements CanActivate, CanLoad {

  constructor(private maintenanceService: MaintenanceService, private router: Router) {}

  // CanActivate guard to protect activated routes
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    return this.checkMaintenance(route.routeConfig?.path);
  }

  // CanLoad guard to protect lazy-loaded modules
  canLoad(route: Route): boolean {
    return this.checkMaintenance(route.path);
  }

  // Helper function to check maintenance status and perform redirection if necessary
  private checkMaintenance(routePath: string | undefined): boolean {
    if (this.maintenanceService.isAppInMaintenance() && routePath !== 'maintenance') {
      this.router.navigate(['/maintenance']);
      return false;
    }
    return true;
  }
}
