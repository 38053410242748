import { Component } from '@angular/core';
import {DisplayCampaignEvent} from "@brightside-web/shared/display";
import {Router} from "@angular/router";

@Component({
  selector: 'brightside-web-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.css']
})
export class LandingComponent {
  termsLanding = {
    ctaKey: 'BUTTON_CONTINUE',
    image: 'fa_intro_1',
    ctaPath: 'terms-update/terms',
    key: 'fa_intro_1',
    titleKey: 'FA_INTRO_TITLE1',
    textBodyKey: 'FA_INTRO_DESC1',
    analytics: {}
  };

  constructor(private router: Router) { }

  handleIntroEvent($event: DisplayCampaignEvent) {
    this.router.navigateByUrl($event.path);
  }
}
