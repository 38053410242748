<div>
  <bw-action-article [brightsideWebFb]="{event_name: 'maintenance'}">
    <div pre-title>
      <img class="mb-sm" src="../assets/warning-cone.png" />
    </div>
    <div header-title>
      <typography title-large color="var(--primary-text)">
        <span [innerHTML]="'MAINTENANCE_TITLE' | translate"></span>
      </typography>
    </div>
    <div content-section>
      <bw-paragraph>{{ 'MAINTENANCE_DESC' | translate }}</bw-paragraph>
    </div>
    <div footer-actions class="flex-row flex-item-flexible space-between">
        <button
          bw-button
          type="submit"
          [brightsideWebFb]="{ event_name: 'maintenance'}"
          (click)="goHome()"
        >
          {{ 'CLOSE' | translate }}
        </button>
    </div>
  </bw-action-article>
</div>
