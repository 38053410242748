import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'brightside-web-maintenance-feature',
  templateUrl: './maintenance-feature.component.html',
  styleUrls: ['./maintenance-feature.component.scss'],
})
export class MaintenanceFeatureComponent {
  constructor(
    private _router: Router,
  ) {
  }
  goHome() {
    this._router.navigate(['home']);
  }
}
