import {
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  AfterViewInit,
  Inject,
  NgZone,
  HostListener,
  inject
} from '@angular/core';
import {DOCUMENT, Location, TitleCasePipe} from '@angular/common';
import {
  ActivatedRoute, convertToParamMap,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Params,
  Router,
  RouterEvent
} from '@angular/router';

import { HubPayload } from '@aws-amplify/core';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import {
  AuthService,
  FirebaseService,
  ProfileService,
  ProfileResponse,
  RemoteConfig_SupportNumber,
  MobileStateResponse,
  RequiredVerificationService,
  CompanyService,
  InternalLinkRoutingService, GlobalModalService, GlobalModalInterface, CacheStorageService, KnownFeatureFlags
} from '@brightside-web/desktop/data-access/shared';
import { RegistrationService } from '@brightside-web/desktop/data-access/onboarding';
import { RoutingStateService, ToastService, Toast, ToastType } from '@brightside/brightside-ui-services';
import { CacheKeys, Environment } from '@brightside-web/micro/core/environment';
import { ToastComponent, MessageService, MenuItem } from '@brightside/brightside-ui';

import {
  AwsApiWrapperService,
  BsAuthService,
  BsCacheService, BsChatService,
  BsHubService, FeatureFlagService, UserAttributesInterface,
} from '@brightside-web/desktop/data-access/core-services';

import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { Intercom } from 'ng-intercom';
import { DeviceDetectorService, OS } from 'ngx-device-detector';
import {from, forkJoin, Subscription, tap} from 'rxjs';
import { filter } from 'rxjs/operators';

import { RoutingService, RoutingServiceDispatchEvent, LayoutStyle } from './routing.service';
import { HttpClient } from '@angular/common/http';
import * as moment from "moment";
import {TranslateService} from "@ngx-translate/core";
import {LanguageSelectorService} from "@brightside-web/desktop/feature/language-selection";

import * as Sentry from '@sentry/angular';

declare let fbq: Function;

declare const BuildVersion: string;
declare const Sprig: Function;
declare const amazon_connect: Function;
declare const vanilla_setupSprig: Function;
declare const vanilla_setupAmazonConnect: Function;

declare global {
  interface Window {
    gTag: Function;
  }
}

interface MappingDetail {
  path: string;
  queryParams?: Record<string, string>;
  queryParamsType?: MappingParamType;
}

interface CompanyConfig {
  supportNumber: string;
}

enum UtmParamEnum {
  utm_source = 'utm_source',
  utm_medium = 'utm_medium',
  utm_campaign = 'utm_campaign',
  utm_content = 'utm_content',
  utm_term = 'utm_term'
}

enum MappingParamType {
  AUTO = 'auto',
  DEFAULT = 'default',
}

interface MobileRouteIgnoreInterface {
  pattern: RegExp | string
}

@Component({
  selector: 'brightside-web-desktop',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {

  awsApiWrapperService: AwsApiWrapperService = inject(AwsApiWrapperService);
  bsAuthService: BsAuthService = inject(BsAuthService);
  bsCacheService: BsCacheService = inject(BsCacheService);
  bsHubService: BsHubService = inject(BsHubService);
  _bsChatService: BsChatService = inject(BsChatService);


  @ViewChild(ToastComponent) toastCmpt: ToastComponent;

  LayoutStyle = LayoutStyle;

  showLogoutWarningModal = false;
  showMobileModal = false;

  //defaults for the mobile content
  isVerifyV2 = false;
  isVerifyV3 = false;
  mobileGraphic = '../assets/Illustration-iOS-LandingPage.svg';
  mobileHeadline = 'DESKTOP_MOBILE_HEADER';
  mobileParagraph = 'DESKTOP_MOBILE_DESC';
  mobileCta = 'DOWNLOAD_MOBILE_APP';
  mobileViewEvent = '';
  mobileLayoutType: LayoutStyle = LayoutStyle.DEFAULT;
  timeToLogout: string;
  showAppLink = true;
  isLoggedIn: boolean;
  isDoneCheckingOnboarding = false;
  isOnboardingComplete = false;
  sub = new Subscription();
  signOutMessage = 'You have successfully signed out.';
  timeOutMessage = 'You have been signed out due to inactivity.';
  signedOutMessage = this.signOutMessage;
  isMobileDevice = false;
  isMobileWidth = false;
  customerLanding: boolean;
  customerName: string;
  customerNumber: string;
  customerNumberLoaded = false;
  subdomain: string;
  timeout: number;
  appLinkSent: boolean;
  currentYear = new Date().getFullYear();
  chatfashown = 0;
  verifyV2 = false;
  showCopy = true;
  isWaitlist = false;
  isReviewApp = false;
  isLandingLayout = false;
  landingHeading = 'BRIGHTSIDE_HEADING';
  landingBodyCopy = 'BRIGHTSIDE_BODY';
  landingIconGroupTitle = 'BRIGHTSIDE_ICON_TITLE';
  isVerify = false;
  showCustomerMessage: boolean;
  customerMessage =
    '*Brightside is an employee benefit. If you’re not sure your company offers Brightside, talk to your HR representative.';
  isLegrand = false;
  isFullScreenLayout = false;

  buildVersion: string = typeof BuildVersion === 'string' ? BuildVersion : 'Local/Develop';

  calledUpdateOwner = false;

  _showIntroVideo: boolean;
  displayLangSelector: boolean;

  mobileRouteIgnoreList: MobileRouteIgnoreInterface[]= [
    {pattern: /^\/verification\/smfa\/\w+$/gm},
  ]
  private _enableSalesforce: boolean;

  set showIntroVideo(value: boolean) {
    if (value) this.analytics.logEvent('video tapped');
    this._showIntroVideo = value;
  }

  get showIntroVideo(): boolean {
    return this._showIntroVideo;
  }

  nativeToDesktopMapping: { [key: string]: any };
  connectedToWatcher = false;
  amazonConnect: Function =
    typeof amazon_connect === 'function'
      ? amazon_connect
      : () => {
          console.log(`Amazon Connect not enabled")`);
        };

  sprig: Function =
    typeof Sprig === 'function'
      ? Sprig
      : () => {
          console.log(`Sprig not enabled")`);
        };
  globalSetupSpring: Function =
    typeof vanilla_setupSprig === 'function'
      ? vanilla_setupSprig
      : () => {
          console.log(`Sprig not enabled")`);
        };

  globalSetupAmazonConnect: Function =
    typeof vanilla_setupAmazonConnect === 'function'
      ? vanilla_setupAmazonConnect
      : () => {
          console.log(`Amazon Connect not enabled")`);
        };

  //This is only used by the router events and should be cleared on navigation end
  private tempRouteURL = '';

  private displayStyle = 'default';
  private displayLastPathKey = '';
  private displayMapping: { [key: string]: string } = {};
  private onboardingChannelReceived = false;

  //Used to stop delayed events if layouts change back to back
  private appLayoutStyleEventTimer: number;

  private faHours = '(Weekdays, 10 a.m. to 7 p.m. ET)';

  bannerModal: GlobalModalInterface = { displayMe: false, type: 'banner', id: '' };

  companyRemapping: { [key: string]: string } = {
    caregiver: 'providence',
  };

  @HostListener('click', ['$event'])
  appOnClick(event: Event) {
    const target = <HTMLElement>event.target;
    if (target.nodeName === 'A') {
      const path = target.getAttribute('href');
      if (path?.startsWith('gobrightside:')) {
        event.stopPropagation();
        event.preventDefault();
        this.internalLinkRouting.routeToLink(path);
      }
    }
  }

  constructor(
    private authService: AuthService,
    private deviceService: DeviceDetectorService,
    private gtmService: GoogleTagManagerService,
    private messageService: MessageService,
    private routingService: RoutingService,
    private routingStateService: RoutingStateService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public env: Environment,
    private idle: Idle,
    private intercom: Intercom,
    private http: HttpClient,
    private titleCase: TitleCasePipe,
    public toastService: ToastService,
    protected analytics: FirebaseService,
    private profileService: ProfileService,
    private _ngZone: NgZone,
    private requiredVerificationService: RequiredVerificationService,
    private companyService: CompanyService,
    private internalLinkRouting: InternalLinkRoutingService,
    private globalModalService: GlobalModalService,
    private translateService: TranslateService,
    @Inject(DOCUMENT) private _document: Document,
    private location: Location,
    private cacheStorageSvc: CacheStorageService,
    private languageSelectorService: LanguageSelectorService,
    private featureFlagSvc: FeatureFlagService
  ) {
    this.sub.add(
      this.languageSelectorService.ready.subscribe(
        isReady => {
          if (isReady && this.languageSelectorService.supportedLanguages.length > 1) {
            this.displayLangSelector = true;
          }
        }
      )
    );

    const queryParams = window.location.href.split('?');

    if (queryParams.length > 1) {
      let hasUtm = false;
      const pairs = queryParams[1].split('&');
      // eslint-disable-next-line guard-for-in
      for (const i in pairs) {
        const keyval = pairs[i].split('=');
        if (keyval[0].includes('utm')) {
          hasUtm = true;
          this.bsCacheService.setItem(keyval[0], keyval[1]);
        }
      }
      if (hasUtm) {
        this.bsCacheService.setItem('referralLink', window.location.href);
        this.bsHubService.listen('bsAuth', (data) => {
          this.bsCacheService.getItem('referralLink').then(
            referralLink => {
              if (referralLink && ['signIn', 'signUp'].includes(data.payload.event)) {
                this.awsApiWrapperService.post('api-mobile', '/referral-app-api', {
                  body: {referralLink}
                });
              }
            }
          );
        });
      }
    }

    this.setUpRoutingEventWatcher();

    this.faHours = 'Weekdays, 8 a.m. to 8 p.m. ET';
    this.bsCacheService.setItem('FAHOURS', this.faHours);

    this.sub.add(
      this.globalModalService.getModal.subscribe((response) => {
        if (response.type === 'banner') {
          this.bannerModal = response;
        } else if (response.type === 'default') {
          this.bannerModal = response;
        }
      })
    );
  }

  onBannerModalClose(modalId: string) {
    this.analytics.logEvent(`${modalId}_dismissed`);
    this.globalModalService.hideModal();
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  public crumbs: MenuItem[];

  ngOnInit() {

    if (this.location.path().includes('review-app')) {
      this.isReviewApp = true;
    }
    this.activatedRoute.queryParams.subscribe((params) => {

      if (document.referrer.includes('link.gobrightside.com') && Object.keys(params).length > 0) this.trackDynamicLink(params);

      // NOTE non-routable components cannot get params from the snapshot, they have to subscribe
      // also see https://medium.com/@tomastrajan/how-to-get-route-path-parameters-in-non-routed-angular-components-32fc90d9cb52
      this.bsCacheService.setItem('referrer', params['referrer'], { expires: moment().add(6000, 'seconds').valueOf() });
      this.bsCacheService.setItem('referrerType', params['referrerType'], { expires: moment().add(6000, 'seconds').valueOf() });
      if (!this.env.production) {
        //this is for automated testing purposes to reach hard-to-access content
        if (this.activatedRoute.snapshot.queryParams['mobile']) {
          this.isMobileDevice = true;
        }
        if (this.activatedRoute.snapshot.queryParams['timeout']) {
          this.idle.setTimeout(+this.activatedRoute.snapshot.queryParams['timeout']);
          this.idle.setIdle(+this.activatedRoute.snapshot.queryParams['timeout']);
        }
      }

      //This is pulled out from the production flag because we need logo during waitlist flow
      if (this.activatedRoute.snapshot.queryParams['company']) {
        let company = this.activatedRoute.snapshot.queryParams['company'];
        company = this.companyRemapping[company] ?? company;
        this.bsCacheService.setItem('fromSubdomain', true);
        this.subdomain = company;
        this.customerLanding = true;
        this.customerName = company;
        this.fetchCompanyFile();
        this.companyService.setCompany(company);
        this.showCustomerMessage = false;
        this.landingHeading = 'BRIGHTSIDE_HEADING_CL';
        this.landingBodyCopy = 'BRIGHTSIDE_BODY_CL';
        this.landingIconGroupTitle = 'BRIGHTSIDE_ICON_TITLE_CL';
      }

      if (params['welcomevideo'] && params['welcomevideo'] === 'true') {
        this._showIntroVideo = true;
      }
    });

    this.isMobileWidth = window.innerWidth < 1024;

    if (this.deviceService.isMobile() || this.deviceService.isTablet()) {
      const hasIgnoredRoute = this.mobileRouteIgnoreList.find(match => {
        const pattern = match.pattern;
        return window.location.pathname.match(pattern) ?? false;
      });
      this.isMobileDevice = hasIgnoredRoute ? false : true;
    }

    /**
     * TODO: want to test this in constructor on dev. would be best to set company into cache ASAP
     * for copy retrieval
     **/
    this.getCustomerBySubDomain();

    this.routingStateService.loadRouting();

    // overrides the default 30 sec timeout setting to 2 min
    this.idle.setTimeout(120);

    // overrides the default 20 min idle setting to 15 min
    this.idle.setIdle(900 - this.idle.getTimeout());

    this.setInterrupts();

    this.sub.add(
      this.idle.onTimeoutWarning.subscribe(() => {
        // show modal with logout and continue
        this.showLogoutWarningModal = true;

        // Stop the interrupts, only want the dismiss to stop the clock
        this.idle.setInterrupts([]);
      })
    );

    // idle.onTimeoutWarning fires every second 2 minutes from timeout
    this.sub.add(
      this.idle.onTimeoutWarning.subscribe((countdown: number) => {
        const minutesToLogout = Math.floor(countdown / 60);
        const currentSecond = countdown - minutesToLogout * 60;
        const secondsStringPluralize = currentSecond === 1 ?
          this.translateService.instant('SECOND') : this.translateService.instant('SECONDS');
        const minutesStringPluralize = minutesToLogout > 1 ?
          this.translateService.instant('MINUTE') : this.translateService.instant('MINUTES');

        // Build time to logout countdown string
        if (minutesToLogout < 1) {
          this.timeToLogout = currentSecond + ' ' + secondsStringPluralize;
        } else {
          this.timeToLogout =
            minutesToLogout + ' ' + minutesStringPluralize + ' ' + this.translateService.instant('AND') + ' ' + currentSecond + ' ' + secondsStringPluralize;
        }
      })
    );

    this.sub.add(
      this.idle.onTimeout.subscribe(() => {
        this.signedOutMessage = this.timeOutMessage;
        this.logout();
        this.analytics.logEvent('sign_out_forced');
      })
    );

    this.bsHubService.listen('bsAuth', (data)=>{
      switch (data.payload.event) {
        case 'signedIn':
          this.setupSprig();
          this.isLoggedIn = true;
          this.bsAuthService.fetchUserAttributes().subscribe(
            attributes => {
              const guid = attributes['guid'];
              if (guid) Sentry.setUser({id: guid})
            }
          );
          this.idle.watch();
          break;
        case 'oAuthSignOut':
        case 'signedOut':
          this.idle.stop();
          break;
        }
    });

    this.bsHubService.listen('OnboardingChannel', (data) => {
      const { payload } = data;
      this.onOnboardingEvent(payload);
    });

    this.bsHubService.listen('IntercomChannel', (data) => {
      if (data.payload.event === 'close') {
        this.featureFlagSvc.getFlag(KnownFeatureFlags.ENABLESALESFORCEMESSAGING)
          .pipe(
            tap(enableSalesforce => {
              if(!enableSalesforce) {
                this.intercom.hide();
              }
            })
          )
          .subscribe();
      }
    });

    this.bsHubService.listen('IntercomChannel', (data) => {
      if (data.payload.event === 'open') {
        this._bsChatService.showChat();
      }
    });

    this.bsHubService.listen('AppChannel', (data) => {
      if (data.payload.event === 'signOut') {
        this.logout();
      }
    });



    this.router.events.pipe(filter((e) => e instanceof RouterEvent)).subscribe((e) => {
      if (e instanceof NavigationError && e.url.length > 1) {
        this.authService.redirectUrl = e.url;
      }
    });

    this.router.events.forEach((item) => {
      if (item instanceof NavigationStart) {
        this.setupMobileContent(item.url);
      } else if (item instanceof NavigationEnd) {
        const { urlAfterRedirects } = item;

        this.isLandingLayout = (urlAfterRedirects.includes('registration') ||
                               urlAfterRedirects.includes('waitlist')) &&
                               !urlAfterRedirects.includes('verification/identity/registration');
        this.isWaitlist = urlAfterRedirects.includes('waitlist');

        if (this.isLandingLayout) {
          if (this.isWaitlist || urlAfterRedirects.includes('get_started')) {
            this.showCopy = false;

            if (this.isWaitlist) {
              this.showAppLink = false;
            }
          }
        }
      }
    });

    this.analytics.ensureInitialized().then(() => {
      this.analytics.fetchConfig().then(() => {
        if (!this.customerNumberLoaded) {
          const rcSupportNumber = this.analytics.getValueAsString(RemoteConfig_SupportNumber);
          this.customerNumber = rcSupportNumber.replace(/[^0-9]/g, '');
          this.customerNumberLoaded = true;
          this.companyService.setSupportNumber(this.customerNumber);
        }
      })
      .catch(error => {
        Sentry.withScope(function (scope) {
          scope.setFingerprint(['Firebase', 'fetchConfig']);
          Sentry.captureException(error);
        });
      });
    })
    .catch(error => {
      Sentry.withScope(function (scope) {
        scope.setFingerprint(['Firebase', 'ensureInitialized']);
        Sentry.captureException(error);
      });
    });
  }

  private trackDynamicLink(queryParams: Params) {
    const eventParams: {[key: string]: string} = {source: 'dynamic link', url: document.referrer};
    let sendEvent = false;
    const utmArray = Object.keys(queryParams).filter((x:string) => {
      const paramArray: string[] = Object.values(UtmParamEnum);
      return paramArray.includes(x.toLowerCase())
    });
    const paramMap = convertToParamMap(queryParams);
    utmArray.forEach(utm => {
      sendEvent = true;
      eventParams[utm] = paramMap.get(utm) as string;
    });
    if (sendEvent) this.analytics.logEvent('link_tapped', eventParams);
  }

  private setupMobileContent(isVerifyV2: string) {
    this.isVerifyV2 = isVerifyV2 === '/verify?v=2';
    this.isVerifyV3 = isVerifyV2 === '/verify?v=3';
    if (this.isVerifyV3) {
      this.mobileViewEvent = 'email_verified';
      this.mobileGraphic = '../assets/verified.svg';
      this.mobileHeadline = 'DESKTOP_EMAIL_NOW_VERIFIED_TITLE';
      this.mobileParagraph = 'DESKTOP_EMAIL_NOW_VERIFIED_DESC';
      this.mobileCta = '';
    } else if (isVerifyV2) {
      this.mobileViewEvent = 'email_verified';
      this.mobileGraphic = '../assets/verified.svg';
      this.mobileHeadline = 'DESKTOP_EMAIL_NOW_VERIFIED_TITLE';
      this.mobileParagraph = 'DESKTOP_EMAIL_NOW_VERIFIED_DESC';
      this.mobileCta = 'DESKTOP_EMAIL_NOW_VERIFIED_CTA';
    } else {
      this.mobileGraphic = '../assets/Illustration-iOS-LandingPage.svg';
      this.mobileHeadline = 'DESKTOP_MOBILE_HEADER';
      this.mobileParagraph = 'DESKTOP_MOBILE_DESC';
      this.mobileCta = 'DOWNLOAD_MOBILE_APP';
      this.mobileViewEvent = 'app_store_redirect';
    }
  }

  private setupSprig() {
    this.globalSetupSpring(this._document, 'https://cdn.sprig.com/shim.js', this.env.sprigId);
    if (this.sprig) {
      console.log(`Sprig setup.`);
    } else {
      console.error(`Sprig not yet setup.`);
    }
  }

  ngAfterViewInit() {
    this.isVerify = window.location.pathname.startsWith('/verify');
  }

  private fetchCompanyFile(file: string = ''): void {
    if (file === '') {
      if (this.customerName) {
        file = this.customerName.toLowerCase();
      } else {
        file = 'app';
      }
    }

    this.awsApiWrapperService.get('api-mobile-noauth', `/cdn-resource/resources/configs/${file}.json`, {body: null})
      .then((data) => {
        this.customerNumber = data.supportNumber;
        this.customerNumberLoaded = true;
        this.companyService.setSupportNumber(this.customerNumber);
      })
      .catch((_) => {
        if (file !== 'app') {
          this.fetchCompanyFile('app');
        } else {
          console.error(`Error, fallback failed~`);
        }
      });

  }

  showMobileDownloadIfApplicable() {
    //ToDo: WHY>? Why do we have all these checks like this. Need to do something about this.
    const isWaitList = window.location.pathname === '/waitlist';

    //Exit if any of these
    if (isWaitList || this.isVerify || this.isReviewApp) {
      return;
    }

    if (this.isMobileDevice) {
      this.downloadApp();
    }
  }

  private setUpRoutingEventWatcher() {
    this.routingService.addOnEventFunction(() => {
      if (!this.isLoggedIn && this.env.production) {
        fbq('track', 'PageView');
      }
    });

    this.bsHubService.listen(RoutingService.DISPATCH_KEY, (data) => {
      const { payload } = data;
      if (payload.event === RoutingServiceDispatchEvent.LAYOUT_CHANGE) {
        this.onAppLayoutStyleEvent(payload.message as LayoutStyle);
      }
    });
  }

  // TODO: maybe move this into BsAuthService, or possibly into authservice and run all calls there

  logout() {
    this._bsChatService.clearSession();
    this.showLogoutWarningModal = false;
    RegistrationService.clearOnboardingCache();
    this.authService.clearConfirmationState();
    this.messageService.clear();
    this.toastService.deleteAll();
    this.toastService.info(this.signOutMessage);
    // this.isLoggedIn = false;
    // this.isDoneCheckingOnboarding = false;
    // this.isOnboardingComplete = false;
    // this.appLinkSent = false;
    this.onboardingChannelReceived = false;
    this.cacheStorageSvc.clear();
    this.signedOutMessage = this.signOutMessage;
    this.crumbs = [];
    this.analytics.logEvent('sign_out_completed');
    this.idle.stop();

    this.bsAuthService.logout().subscribe({
      next: () => {

      }
    })
  }

  signIn() {
    this.router.navigate(['/sign_in']);
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
    clearTimeout(this.timeout);
  }

  logoClick() {
    if (this.isLoggedIn) {
      this.router.navigate(['/home']);
    } else {
      this.analytics.logEvent('logo tapped');
      window.location.href = '//www.gobrightside.com/employees/';
    }
  }

  onAppLayoutStyleEvent(style: LayoutStyle = LayoutStyle.DEFAULT) {
    //Same layout style
    this.mobileLayoutType = style;

    if (this.appLayoutStyleEventTimer) {
      window.clearTimeout(this.appLayoutStyleEventTimer);
    }

    switch (style) {
      case LayoutStyle.FULL_SCREEN:
        this.isFullScreenLayout = true;

        this.appLayoutStyleEventTimer = window.setTimeout(() => {
          this.showMobileDownloadIfApplicable();
          this.featureFlagSvc.getFlag(KnownFeatureFlags.ENABLESALESFORCEMESSAGING)
            .pipe(
              tap(enableSalesforce => {
                if(!enableSalesforce) {
                  this.intercom.update({ vertical_padding: 8000 });
                }
              })
            )
            .subscribe();

        }, 100);
        break;

      case LayoutStyle.PUBLIC_ACCESS:
        this.isFullScreenLayout = true;

        this.appLayoutStyleEventTimer = window.setTimeout(() => {
          this.featureFlagSvc.getFlag(KnownFeatureFlags.ENABLESALESFORCEMESSAGING)
            .pipe(
              tap(enableSalesforce => {
                if(!enableSalesforce) {
                  this.intercom.update({ vertical_padding: 8000 });
                }
              })
            )
            .subscribe();
        }, 100);
        break;

      default:
        this.isFullScreenLayout = false;

        this.appLayoutStyleEventTimer = window.setTimeout(() => {
          this.showMobileDownloadIfApplicable();
          this.featureFlagSvc.getFlag(KnownFeatureFlags.ENABLESALESFORCEMESSAGING)
            .pipe(
              tap(enableSalesforce => {
                if(!enableSalesforce) {
                  this.intercom.update({ vertical_padding: 0 });
                }
              })
            )
            .subscribe();
        }, 100);
    }
  }

  onOnboardingEvent(payload: HubPayload) {

    switch (payload.event) {
      case 'SurveyComplete':
        this.loadChat();
        break;
      case 'OnboardingComplete':
        this.isOnboardingComplete = true;
        this.loadChat();
        break;
      case 'GetTheAppComplete':
        this.appLinkSent = true;
        break;
      case 'SignUp':
        break;
      default:
        this.isOnboardingComplete = true;
    }
  }

  loadChat() {
    this.featureFlagSvc.getFlag<boolean>(KnownFeatureFlags.ENABLESALESFORCEMESSAGING)
      .pipe(
        tap(enableSalesforce => {
          this._enableSalesforce = enableSalesforce;
          if(enableSalesforce) {
            this._bsChatService.loadChat();
          } else {
            this.loadIntercom();
            this.intercomLauncherFrame();
          }
        })
      )
      .subscribe();
  }

  toastClicked($event: Toast) {
    if ($event.type === ToastType.Error) {
      this.bsHubService.dispatch('ErrorChannel', { event: 'RetryEvent', message: 'user clicked toast retry link' });
    } else {
      this.bsHubService.dispatch('ToastChannel', { event: 'ToastEvent', data: $event, message: 'user clicked toast link' });
    }
  }

  dismissAutoLogout() {
    // stop idle timers so we can restart the watch later
    this.idle.stop();

    // hide warning modal
    this.showLogoutWarningModal = false;

    // wait a sec and then reset the interupts and start watching for idle
    this.timeout = window.setTimeout(() => {
      this.setInterrupts();
      this.idle.watch();
    }, 1000);
  }

  downloadApp() {
    if (this.deviceService.getDeviceInfo().os === OS.ANDROID) {
      document.location.href = 'https://play.google.com/store/apps/details?id=com.androidapp.gobrightside.mobile';
    } else if (this.deviceService.getDeviceInfo().os === OS.IOS) {
      document.location.href = 'https://itunes.apple.com/us/app/brightsidebenefit/id1250624924?mt=8';
    }
  }

  private setInterrupts() {
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
  }

  getCustomerBySubDomain() {
    const hostnameSplit = window.location.hostname.toLowerCase().split('.');
    const domain = hostnameSplit[hostnameSplit.length - 2];
    if (hostnameSplit[0] === 'app' || (domain !== 'gobrightside' && domain !== 'brightsidefinancial')) {
      this.subdomain = '';
      this.customerLanding = false;
      this.showCustomerMessage = true;
      this.customerMessage =
        '*Brightside is an employee benefit. If you’re not sure your company offers Brightside, talk to your HR representative.';
    } else {
      this.subdomain = hostnameSplit[0];
      this.customerLanding = true;
      this.customerName = this.companyRemapping[this.subdomain] ?? this.subdomain;
      this.companyService.setCompany(this.customerName);
      this.bsCacheService.setItem('fromSubdomain', true);
    }
    // this will get picked up for all subdomains, need to do something about it (can we remove?)
    if (this.customerLanding) {
      const customerNameFormatted = this.titleCase.transform(this.customerName);

      this.showCustomerMessage = false;
      this.landingHeading = 'BRIGHTSIDE_HEADING_CL';
      this.landingBodyCopy = 'BRIGHTSIDE_BODY_CL';
      this.landingIconGroupTitle = 'BRIGHTSIDE_ICON_TITLE_CL';
      // this.landingHeading = 'Want more money? You’re in the right place.';
      // this.landingBodyCopy = `No matter what your financial needs might be—anything from simply making rent to saving for retirement—Brightside can take care of it. And it’s completely free for ${customerNameFormatted} employees!`;
      // this.landingIconGroupTitle = 'With your expert Brightside Financial Assistant, you can:';
    }
    if (this.subdomain === 'legrand') {
      this.showCustomerMessage = true;
      this.isLegrand = true;
    }
    const subdomains = ['providence', 'caregiver', 'amyskitchen', 'amazon', 'meijer'];
    if (subdomains.includes(this.subdomain)) {
      this.showCustomerMessage = true;
    }

    this.fetchCompanyFile();
  }

  getTheApp() {
    this.appLinkSent = false;
    this.router.navigate(['/registration', 'get-the-app']);
  }

  async intercomLauncherFrame() {
    const launcherClass = 'intercom-launcher-frame';
    const hasBeenClosed = await this.bsCacheService.getItem('INTERCOM_HAS_BEEN_CLOSED_BEFORE');
    const maxRetryAttempts = 5;
    const tryToConnectWatcher = () => {
      setTimeout(() => {
        try {
          const nodeLists = document.getElementsByName(launcherClass);
          const iFrameElement = nodeLists[0] as HTMLIFrameElement;
          const contentDocument = iFrameElement?.contentDocument as HTMLDocument;

          contentDocument.removeEventListener(`click`, () => {});
          contentDocument.addEventListener(`click`, (_) => {
            if (hasBeenClosed) return;

            this.bsCacheService.setItem('INTERCOM_HAS_BEEN_CLOSED_BEFORE', true, {
              expires: new Date().setFullYear(new Date().getFullYear() + 1),
            });
          });
        } catch (error) {
          if (maxRetryAttempts > 0 && maxRetryAttempts < retriesCompleted) {
            return;
          }
          setTimeout(() => {
            tryToConnectWatcher();
            retriesCompleted++;
          }, 500);
        }
      }, 500);
    };
    let retriesCompleted = 0;

    tryToConnectWatcher();
  }

  /**
   * This method will check the DOM for the correct iframe and attached listeners as needed
   * withFrame string Target frame name to attach listeners to DEFAULTS to intercom main messenger frame
   * maxRetryAttempts number This should be used to limit the amount of times the DOM will be searched for a given frame DEFAULT 0 will go forever
   *
   * These mappings will come back in sprint 109 queryParams will also need to be covered there
   * ['finsol/deep/savings/autosave/once']: { path: 'home' },
   */
  startIntercomClickWatcher(withFrame: string = 'intercom-messenger-frame', maxRetryAttempts: number = 0) {
    this.nativeToDesktopMapping = this.nativeToDesktopMapping
      ? this.nativeToDesktopMapping
      : this.analytics.remoteConfigMapping();

    const logDeepEvent = (redirectTo: string, isError: boolean = false) => {
      this.analytics.logEvent(isError ? 'error' : 'click', {
        action: 'submit',
        page: 'global',
        category: 'deep_link',
        custom: {
          redirect: redirectTo,
        },
      });
    };

    const routeMainAppTo = (routeToString: string, routeQueryToString: string) => {
      if (Object.keys(this.nativeToDesktopMapping).includes(routeToString)) {
        const routeToOptions: MappingDetail = this.nativeToDesktopMapping[routeToString] || { path: '' };
        const additionalOptions = { queryParams: {} };

        logDeepEvent(routeToOptions.path);

        if (routeToOptions.queryParamsType === MappingParamType.AUTO && routeQueryToString) {
          const splitRouteQuery = routeQueryToString.split('&');

          additionalOptions.queryParams = splitRouteQuery.reduce((finalParams: { [key: string]: string }, paramValue: string) => {
            const brokenValue = paramValue.split('=');

            finalParams[brokenValue[0]] = brokenValue[1];

            return finalParams;
          }, {});
        }

        //This needs to be run inside angular to correctly handle routing later
        this._ngZone.run(() => {
          this.router.navigate(routeToOptions.path.split('/'), additionalOptions).finally(() => {
            this.featureFlagSvc.getFlag(KnownFeatureFlags.ENABLESALESFORCEMESSAGING)
              .pipe(
                tap(enableSalesforce => {
                  if(!enableSalesforce) {
                    this.intercom.hide();
                  }
                })
              )
              .subscribe();
          });
        });
      } else {
        this.showMobileModal = true;
        logDeepEvent(routeToString, true);
      }
    };

    const tryToConnectWatcher = () => {
      setTimeout(() => {
        try {
          const nodeLists = document.getElementsByName(withFrame);
          const iFrameElement = nodeLists[0] as HTMLIFrameElement;
          const contentDocument = iFrameElement?.contentDocument as HTMLDocument;

          //If anything is null try to watch again
          if (!nodeLists || !iFrameElement || !contentDocument) {
            this.startIntercomClickWatcher();
            return;
          }

          contentDocument.removeEventListener(`click`, () => {});
          contentDocument.addEventListener(`click`, (e: any) => {
            const origin = e.target.closest('a') as HTMLAnchorElement;
            if (origin && origin.href.includes('gobrightside://')) {
              e.preventDefault();
              this.internalLinkRouting.routeToLink(origin.href);
            }
          });
          this.connectedToWatcher = true;
        } catch (error) {
          if (maxRetryAttempts > 0 && maxRetryAttempts < retriesCompleted) {
            return;
          }

          setTimeout(() => {
            tryToConnectWatcher();

            retriesCompleted++;
          }, 1000);
        }
      }, 1000);
    };

    let retriesCompleted = 0;

    tryToConnectWatcher();
  }

  async checkAndSetupSprig(profile: UserAttributesInterface) {
    const mobileState = await this.bsCacheService.getItem('/client/mobilestate') as MobileStateResponse;

    let enableSprig = false;

    if (mobileState && mobileState.flags) {
      enableSprig = mobileState.flags.enableSprig;
    } else if (profile.company === 'brightside') {
      enableSprig = true;
    }

    if (enableSprig) {
      //ensure these are set, they are getting unset after called.
      this.sprig =
        typeof Sprig === 'function'
          ? Sprig
          : () => {
              console.log(`Sprig not enabled")`);
            };
      this.globalSetupSpring =
        typeof vanilla_setupSprig === 'function'
          ? vanilla_setupSprig
          : () => {
              console.log(`Sprig not enabled")`);
            };

      console.log(`Enabling sprig...`);
      this.setupSprig();
      this.bsCacheService.getItem(CacheKeys.Guid).then(
        guid => {
          if (this.sprig) {
            console.log(`Setting up email for Sprig`);
            this.sprig('setEmail', profile.email);
            this.sprig('setUserId', guid);
          }
        }
      )
    }
  }

  async loadIntercom() {
    const obs = this.bsAuthService.fetchUserAttributes();
    const fromOnboarding = await this.bsCacheService.getItem('fromOnboarding');

    this.sub.add(
      obs.subscribe((attribs) => {
        const attributes: UserAttributesInterface = attribs;

        this.checkAndSetupSprig(attributes);

          const intercomUserId = attributes['intercom_external_id']
            ? attributes['intercom_external_id']
            : attributes.phone_number;
          const regex = /%1\$s/gi;
          const data = {
            app_id: this.env.intercomAppId,
            // Supports all optional configuration.
            widget: {
              activator: '#intercom',
            },
            name: attributes['first_name'] + ' ' + attributes['last_name'],
            phone: attributes['phone_number'],
            email: attributes['email'],
            user_id: intercomUserId,
            guid: attributes['guid'],
            'crm link': this.env.crmUrl.replace(regex, attributes['sf_contact_id'] as string),
            hide_default_launcher: false,
          };

          this.intercom.boot(data);
          if (fromOnboarding && !this.calledUpdateOwner) {
            this.calledUpdateOwner = true;
            setTimeout(() => {
              this.bsHubService.dispatch('ReportIntercomFirstTime', { event: 'ReportIntercomFirstTime' });
            }, 1000);
          }
          this.intercom.onHide(() => {
            document.getElementById('body')?.classList.remove('intercom-open');
          });

          this.intercom.onShow(() => {
            // if fa sends a message and a client opens chat within 15 sec we will attribute the chat to the fa message
            const cutoff = Date.now() - 15000;

            setTimeout(() => {
              if (this.chatfashown > cutoff) {
                this.analytics.logEvent('chat_shown', { source: 'fa' }, true);
              } else {
                this.analytics.logEvent('chat_shown', { source: 'client' }, true);
              }
            }, 250);

            document.getElementById('body')?.classList.add('intercom-open');
            this.startIntercomClickWatcher();
          });

          this.intercom.onUnreadCountChange(() => {
            this.chatfashown = Date.now();
            this.startIntercomClickWatcher('intercom-notifications-frame', 5);
          });

          //ToDo: Find smarter way to do this instead of polling :(
          this.startIntercomClickWatcher('intercom-borderless-frame');

      },
      err => {
        // catching no active session error
      }
      )
    );
  }
}
