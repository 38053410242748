import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'brightside-web-nested-form-group',
  templateUrl: './nested-form-group.component.html',
  styleUrls: ['./nested-form-group.component.scss']
})
export class NestedFormGroupComponent implements OnInit {
  @Input() fGroup:FormGroup;
  @Input() fgName:string;

  constructor() { }

  ngOnInit() {
  }

}
