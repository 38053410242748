import { FormGroup } from '@angular/forms';
import {
  HubPayload,
  MessageBusEventChannel,
  MessageBusIncomingEventKey,
  MessageBusInternalService,
} from '@brightside-web/micro/core/message-bus';

import { take } from 'rxjs/operators';

import { SharedDisplayFormControlConfig, SharedDisplayFormDocumentUtil } from '../../../index';

export const SharedDisplayFormControlDocumentUploadClickResponder = (
  FOCUSED_FORM_GROUP: FormGroup,
  FOCUSED_CONTROL_CONFIG: SharedDisplayFormControlConfig
) => {
  //TODO translate this default filename
  const DocumentUploadMarkControlFilled = (fileName: string = 'document') => {
    if (FOCUSED_CONTROL_CONFIG.cellDetails?.action) {
      FOCUSED_CONTROL_CONFIG.cellDetails.action.emphasize = SharedDisplayFormDocumentUtil.getSetting.selected.emphasize;
      FOCUSED_CONTROL_CONFIG.cellDetails.iconNameRight = SharedDisplayFormDocumentUtil.getSetting.selected.icon;

      (FOCUSED_CONTROL_CONFIG.cellDetails as any).originalLabelSubLeft = FOCUSED_CONTROL_CONFIG.cellDetails.labelSubLeft;
      FOCUSED_CONTROL_CONFIG.cellDetails.labelSubLeft = fileName;

      FOCUSED_FORM_GROUP.get(FOCUSED_CONTROL_CONFIG.key)?.setValue(fileName);

      DocumentUploadAddRemoveActionEvent();
    }
  };
  const DocumentUploadAddRemoveActionEvent = () => {
    if (FOCUSED_CONTROL_CONFIG.cellDetails?.action) {
      (FOCUSED_CONTROL_CONFIG.cellDetails as any).originalActionEvent = FOCUSED_CONTROL_CONFIG.cellDetails.action.event;
      FOCUSED_CONTROL_CONFIG.cellDetails.action.event = SharedDisplayFormDocumentUtil.getSetting.deleteHalfSheet;
    }
  };
  const DocumentUploadRemoveActionEvent = () => {
    if (FOCUSED_CONTROL_CONFIG.cellDetails?.action) {
      FOCUSED_CONTROL_CONFIG.cellDetails.action.event = (FOCUSED_CONTROL_CONFIG.cellDetails as any).originalActionEvent;
      delete (FOCUSED_CONTROL_CONFIG.cellDetails as any).originalActionEvent;
    }
  };

  const DocumentUploadCaptureHandler = () => {
    SharedDisplayFormDocumentUtil.startDocumentUploadCapture(FOCUSED_CONTROL_CONFIG.key)
      .pipe(take(1))
      .subscribe((fileName: string) => {
        //ToDo: should we do anything with the case of not picking a file... tracking at least?
        if (fileName) {
          FOCUSED_FORM_GROUP.markAsTouched();
          FOCUSED_FORM_GROUP.markAsDirty();

          DocumentUploadMarkControlFilled(fileName);
        }
      });
  };
  const DocumentUploadPickerHandler = () => {
    SharedDisplayFormDocumentUtil.startDocumentUploadPicker(FOCUSED_CONTROL_CONFIG.key)
      .pipe(take(1))
      .subscribe((fileName: string) => {
        //ToDo: should we do anything with the case of not picking a file... tracking at least?
        if (fileName) {
          FOCUSED_FORM_GROUP.markAsTouched();
          FOCUSED_FORM_GROUP.markAsDirty();

          DocumentUploadMarkControlFilled(fileName);
        }
      });
  };
  const DocumentUploadDeleteHandler = () => {
    SharedDisplayFormDocumentUtil.removeSelectedDocument(FOCUSED_CONTROL_CONFIG.key);

    FOCUSED_FORM_GROUP.markAsUntouched();
    FOCUSED_FORM_GROUP.get(FOCUSED_CONTROL_CONFIG.key)?.setValue('');

    if (FOCUSED_CONTROL_CONFIG.cellDetails?.action) {
      FOCUSED_CONTROL_CONFIG.cellDetails.action.emphasize = SharedDisplayFormDocumentUtil.getSetting.unselected.emphasize;
      FOCUSED_CONTROL_CONFIG.cellDetails.iconNameRight = SharedDisplayFormDocumentUtil.getSetting.unselected.icon;
      FOCUSED_CONTROL_CONFIG.cellDetails.labelSubLeft = (FOCUSED_CONTROL_CONFIG.cellDetails as any).originalLabelSubLeft;

      delete (FOCUSED_CONTROL_CONFIG.cellDetails as any).originalLabelSubLeft;

      DocumentUploadRemoveActionEvent();
    }
  };

  /**
   * This watcher will wait for events from the halfsheet bridge info
   * and execute the mapped callback for the code provided.
   */
  MessageBusInternalService.addHubListenerWithEventFilter({
    channel: MessageBusEventChannel.INCOMING,
    filterByEvents: [MessageBusIncomingEventKey.HALF_SHEET],
    take: 1, //Note this is here to make sure we don't get more than one image selector shown
    callbackListener: (payload: HubPayload) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const code = payload.data.body.code;

      if (code === 'documentUpload_NewPicture') {
        DocumentUploadCaptureHandler();
      } else if (code === 'documentUpload_ChoosePhoto') {
        DocumentUploadPickerHandler();
      } else if (code === 'documentUpload_DeleteFile') {
        DocumentUploadDeleteHandler();
      }
    },
  });
};
