<bw-modal
  *ngIf="currentModal$|async as modal"
  [modalWidth]="modal.size"
  [modalHeight]="modal.minHeight"
  [showCloseButton]="modal.displayCloseButton"
  (closeModal)="closeModal(modal)">
  <ng-container [ngSwitch]="modal.isError">
    <ng-container *ngSwitchCase="true">
      <div [brightsideWebFb]="{ event_name: 'error_shown', custom: { 'error id': modal.eventName || 'modal unknown' }}"></div>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <div *ngIf="modal.eventName" [brightsideWebFb]="{ event_name: modal.eventName}"></div>
    </ng-container>
  </ng-container>
  <div *ngIf="modal.headerImagePath || modal.title" title-medium>
    <div *ngIf="modal.headerImagePath" class="modal-header-img mb-sm">
      <img [attr.src]="modal.headerImagePath">
    </div>
    <typography
      *ngIf="modal.subtitle && modal.eyebrow"
      text-medium-regular
      color="var(--secondary-text)"
      class="mt-sm">
      {{modal.subtitle|translate}}
    </typography>
    <typography
      *ngIf="modal.title">
      {{ modal.title | translate }}
    </typography>
  </div>
  <section class="body" [ngClass]="modal.bodyClass">
    <ng-container *ngIf="modal.templateRef; else defaultContent">
      <ng-container *ngTemplateOutlet="modal.templateRef"></ng-container>
    </ng-container>
    <ng-template #defaultContent>
      <bw-spinner *ngIf="modal.showSpinner"></bw-spinner>
      <typography
        *ngIf="modal.subtitle && !modal.eyebrow"
        text-medium-regular
        color="var(--secondary-text)"
        class="mb-sm">
        {{modal.subtitle|translate}}
      </typography>
      <typography
        *ngIf="modal.contentKey"
        text-medium-regular
        class="mb-sm">
        <div [innerHTML]="modal.contentKey|translate"></div>
      </typography>
    </ng-template>
  </section>
  <footer
    *ngIf="modal.ctaKey || modal.secondaryCtaKey"
    class="footer">
    <div class="flex-row row-reverse">
      <button
        *ngIf="modal.ctaKey" bw-button
        [ngClass]="modal.ctaType || ''"
        (click)="handleCtaClick(modal.ctaAction, modal.loadingAfterCta)"
        [brightsideWebFb]="{ event_name: modal.eventName}"
        [processing]="modalLoadingCta$ | async">
        {{ modal.ctaKey | translate }}
      </button>

      <button
        *ngIf="modal.secondaryCtaKey"
        bw-link-button
        class="bs-blue pr-xl"
        (click)="handleCtaClick(modal.secondaryCtaAction)"
        [brightsideWebFb]="{ event_name: modal.eventName}" [secondaryCta]="true">
        {{ modal.secondaryCtaKey | translate }}
      </button>
    </div>
  </footer>
</bw-modal>
