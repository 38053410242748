<div id="maintenance-wrapper">
  <bw-action-article [brightsideWebFb]="{event_name: 'app_maintenance'}">
    <div pre-title>
      <img class="mb-sm" src="../assets/maintenance-pig.png" />
    </div>
    <div header-title>
      <typography title-large color="var(--primary-text)">
        {{ 'APP_MAINTENANCE_TITLE' | translate }}
      </typography>
    </div>
    <div content-section>
      <bw-paragraph>{{ 'APP_MAINTENANCE_DESC' | translate }}</bw-paragraph>

    </div>
  </bw-action-article>
</div>
