import { Renderer2, Inject, Injectable, RendererFactory2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';


@Injectable()
export class SocureService {
  SCRIPT_PATH = 'https://websdk.socure.com/bundle.js';

  socureErrorType = {
    7011: 'Invalid Key',
    7014: 'Session Expired',
    7021: 'Initiate Session Failed',
    7022: 'Document Upload Failed',
    7101: 'Socure Key Empty',
    7102: 'Camera Permission Disabled',
    7103: 'No Internet Connection',
    7104: 'Scan Canceled By User',
    7106: 'Camera Error',
    7107: 'Unknown Error',
    7110: 'Consent Declined',
  };

  private renderer: Renderer2;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private _rendererFactory: RendererFactory2
  ) { 
    this.renderer = _rendererFactory.createRenderer(null, null);
  }

  loadSocureScript(): HTMLScriptElement {
    const script = this.renderer.createElement('script');
    script.type = 'text/javascript';
    script.src = this.SCRIPT_PATH;
    this.renderer.appendChild(this.document.body, script);
    return script;
  }
}
