import { Environment } from '@brightside-web/micro/core/environment';

import { FirebaseApp, initializeApp } from 'firebase/app';
import { Analytics, getAnalytics, setUserProperties } from 'firebase/analytics';

import { FirebaseRemoteConfigure } from './remote.config';

export class FirebaseConfigure {
  protected currentApp: FirebaseApp;
  protected environment: Environment;

  protected remoteConfigure = new FirebaseRemoteConfigure();
  protected analytics: Analytics;

  constructor() {}

  private getSubDomain() {
    const hostnameSplit = window.location.hostname.toLowerCase().split('.');
    const tldDomain = hostnameSplit[hostnameSplit.length - 2];
    if (hostnameSplit[0] === 'app' || tldDomain !== 'gobrightside') {
      return '';
    } else {
      return hostnameSplit[0];
    }
  }

  private getEnvironment() {
    const hostname = window.location.hostname.toLowerCase();

    if (this.environment.production) {
      return 'prod';
    } else if (hostname.includes('stage')) {
      return 'stage';
    } else {
      return 'dev2';
    }
  }

  private getProperties() {
    const properties: any = {};

    const company = this.getSubDomain();
    const env = this.getEnvironment();

    if (company) {
      properties['company'] = company;
    }

    if (env) {
      properties['environment'] = env;
    }

    console.log('debug env prop', properties);

    return properties;
  }

  public setUpConfig(environment: Environment) {
    this.environment = environment;
    this.currentApp = initializeApp(environment.firebase);

    this.remoteConfigure.initRemoteConfig(this.currentApp, this.environment);
    this.analytics = getAnalytics(this.remoteConfigure.instance.app);

    setUserProperties(this.analytics, this.getProperties());

    //Do this AFTER user prop so that it will pick up env changes
    this.remoteConfigure.fetchAndActivate();

    return this.currentApp;
  }
}
