import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Route, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { MaintenanceService } from './maintenance.service';

@Injectable({
  providedIn: 'root'
})
export class MaintenanceGuard implements CanActivate, CanLoad {

  constructor(
    private maintenanceService: MaintenanceService,
    private router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const productType = route.data['product'] as string;

    if (productType && this.maintenanceService.isProductInMaintenance(productType)) {
      this.router.navigate(['/maintenance/feature']);
      return false;
    }

    return true;
  }

  canLoad(route: Route): boolean {
    const productType = route.data?.['product'] as string;

    if (productType && this.maintenanceService.isProductInMaintenance(productType)) {
      this.router.navigate(['/maintenance/feature']);
      return false;
    }
    return true;
  }
}
