import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ErrorHandler, NgModule, NgZone } from '@angular/core';
import { Router, RouteReuseStrategy } from '@angular/router';

import { CurrencyPipe, TitleCasePipe } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { NgIdleModule } from '@ng-idle/core';
import { TraceService } from '@sentry/angular';
import { DesktopDataAccessCoreServicesModule } from "@brightside-web/desktop/data-access/core-services";
import { BrightsideUiModule, NgxMaskModule, ngxMaskOptions, MessageService } from '@brightside/brightside-ui';
import {
  DesktopDataAccessSharedModule,
  FirebaseAnalyticsService,
  FirebaseService,
  AmplitudeService,
  BrightsideTranslateService,
  CompanyService,
  StatusNotificationsService,
} from '@brightside-web/desktop/data-access/shared';
import { BsErrorHandler, SharedDesktopModule } from '@brightside-web/shared/desktop';
import { DesktopDataAccessEmailVerifyModule } from '@brightside-web/desktop/data-access/email-verify';
import { BrightsideUiServicesModule } from '@brightside/brightside-ui-services';
import { MicroMessageBusModule, MessageBusIncomingService } from '@brightside-web/micro/core/message-bus';
import { Environment } from '@brightside-web/micro/core/environment';

import { IntercomModule } from 'ng-intercom';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { VerifyComponent } from './verify/verify.component';
import { env, environment } from '../environments/environment';
import { WaitlistComponent } from './waitlist/waitlist.component';
import { WaitlistService } from './waitlist.service';
import { RoutingService } from './routing.service';
import { SharedMobileModule } from '@brightside-web/shared/mobile';

import {HttpClient, HttpClientModule} from "@angular/common/http";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {TranslateLoader, TranslateModule, TranslateService} from "@ngx-translate/core";
import {
  LanguageSelectionModule
} from "@brightside-web/desktop/feature/language-selection";

import { BsApiService, FeatureFlagService } from "@brightside-web/desktop/data-access/core-services";

import { SimpleGlobalModalComponent } from "./global-modal/simple-global-modal.component";

import {DesktopFeatureFinancialAccountsCoreModule} from "@brightside-web/desktop/feature/financial-accounts-core";

import { BsHubService, BsAuthService, BsCacheService } from "@brightside-web/desktop/data-access/core-services";
import {AuthModule} from "@auth0/auth0-angular";

export function HttpLoaderFactory(http: HttpClient) {
  const origin = window.location.origin;
  return new TranslateHttpLoader(
    http,
    `${origin}/assets/i18n/`,
    '.json'
  );
}

@NgModule({
  declarations: [AppComponent, VerifyComponent, WaitlistComponent, SimpleGlobalModalComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    BrightsideUiModule,
    NgIdleModule.forRoot(),
    DesktopDataAccessSharedModule,
    DesktopDataAccessEmailVerifyModule,
    IntercomModule.forRoot({
      appId: env.intercomAppId,
    }),
    SharedDesktopModule,
    ReactiveFormsModule,
    BrightsideUiServicesModule,
    NgxMaskModule.forRoot(ngxMaskOptions),
    SharedMobileModule,
    MicroMessageBusModule,
    HttpClientModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    LanguageSelectionModule,
    DesktopDataAccessCoreServicesModule,
    DesktopFeatureFinancialAccountsCoreModule,
    AuthModule.forRoot({
      domain: env.auth0.domain,
      clientId: env.auth0.clientId,
      authorizationParams: {
        redirect_uri: window.location.origin + '/authenticating',
        audience: env.auth0.audience,
        scope: 'openid profile email phone_number read:current_user read:current_user_metadata offline_access'
      },
      cacheLocation: 'localstorage',
      useRefreshTokens: true,

    })
  ],
  providers: [
    BsHubService, BsAuthService, BsCacheService,
    AmplitudeService,
    { provide: Environment, useValue: environment },
    {
      provide: TraceService,
      deps: [Router],
    },
    { provide: FirebaseService, useClass: FirebaseAnalyticsService },
    { provide: ErrorHandler, useClass: BsErrorHandler },
    { provide: 'googleTagManagerId', useValue: environment.googleTagManagerId },
    { provide: TranslateService, useClass: BrightsideTranslateService },
    CurrencyPipe,
    TitleCasePipe,
    MessageService,
    WaitlistService,
    RoutingService,
    CompanyService,
    StatusNotificationsService,
    BsApiService,
    FeatureFlagService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    private messageBusIncomingService: MessageBusIncomingService,
    private _ngZone: NgZone,
    private _traceService: TraceService
  ) {
    (window as any).BsCrossCom = {};
    (window as any).BsCrossCom.publishMessage = this.publishMessage.bind(this);
  }

  /**
   *
   * @param rawNativeMessage: string - Example: 'auth <tokenId>'
   */
  publishMessage(rawNativeMessage: string): void {
    this._ngZone.run(() => this.messageBusIncomingService.handleIncoming(rawNativeMessage));
  }
}
