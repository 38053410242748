<div class="bw-radio-wrapper"
     [ngClass]="{
     'radio-list': radioList,
     'radio-block': radioBlock,
     'checked': radio.checked,
     'radio-single-margin': singleMargin,
     'radio-list_border': radioList && listStyle === 'border'}">
  <div class="bw-radio-hidden">
    <input
      #radio
      type="radio"
      [attr.id]="inputId"
      [attr.name]="name"
      [attr.value]="value"
      [attr.tabindex]="tabindex"
      [checked]="checked"
      (change)="onChange($event)"
      (focus)="onRadioFocus($event)"
      (blur)="onRadioBlur($event)"
      [disabled]="disabled"
    />
  </div>
  <div
    (click)="handleClick($event, radio, true)"
    role="radio"
    [attr.aria-checked]="checked"
    class="bw-faux-radio"
    [ngClass]="{
      'bw-radio-active': radio.checked,
      'bw-radio-disabled': disabled,
      'bw-radio-focus': focused,
      'bw-radio-single-margin': singleMargin
    }"
  ></div>
  <label class="body" (click)="select($event)" *ngIf="label" [attr.for]="inputId">
    <span *ngIf="preLabel" class="type-xs-bold brand" [innerHTML]="preLabel"></span>
    <span [ngClass]="{'type-xl-bold mb-xxs': isBoldLabel}" [innerHTML]="label"></span>
    <span *ngIf="postLabel" class="type-sm secondary-text" [innerHTML]="postLabel"></span>
  </label>
</div>
