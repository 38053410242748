import { NavigationExtras } from '@angular/router';

import { UiTemplateStateKey } from '../../service/state-handler/state.enum';
import { UiTemplatePageStateCta, UiTemplatePageStateForm } from '../../service/state-handler/state.interface';

import {
  UiTemplateAction,
  UiTemplateActionInjectorFunctionType,
  UiTemplateBaseAction,
  UiTemplateBaseActionFunction,
  UiTemplateLifecycleHook,
  UiTemplateNavType,
} from './page-template.enum';

interface UiTemplateBaseDeveloper {
  logPrefix?: string;
  allowVerboseDebugMode?: boolean;
}

interface UiTemplateBaseInputs {
  navType?: UiTemplateNavType;

  link?: string; //Will show a link in the footer-top above CTAs
  infoText?: string; //Will show a string of info in the footer-top above CTAs
  confirmBox?: string; //Will show a checkbox with this copy in the footer-top above CTAs - State of checkbox value is under: footerChecked

  cta1Label?: string;
  cta2Label?: string;
}

interface UiTemplateBaseActions {
  [UiTemplateAction.cta1]?: UiTemplateBaseAction;
  [UiTemplateAction.cta2]?: UiTemplateBaseAction;
  [UiTemplateAction.footerLink]?: UiTemplateBaseAction;
  [UiTemplateAction.footerLink]?: UiTemplateBaseAction;
  [UiTemplateAction.formValueChanged]?: UiTemplateBaseActionFunction;
  [UiTemplateAction.headerMenuItem]?: UiTemplateBaseAction;
  [UiTemplateAction.navLeftIcon]?: UiTemplateBaseAction;
  [UiTemplateAction.stateChanged]?: UiTemplateBaseActionFunction;
}

interface UiTemplateBaseInterface {
  inputs: UiTemplateBaseInputs;
  actions: UiTemplateBaseActions;
  hooks?: UiTemplateBaseLifecycleHooks;
  developer?: UiTemplateBaseDeveloper;
}

interface UiTemplateBaseState {
  [UiTemplateStateKey.CTA_1]?: UiTemplatePageStateCta;
  [UiTemplateStateKey.CTA_2]?: UiTemplatePageStateCta;

  [UiTemplateStateKey.FORM]?: UiTemplatePageStateForm;
}

interface UiTemplateBaseLifecycleHooks {
  [UiTemplateLifecycleHook.templateDataInitLoaded]?: UiTemplateActionInjectorFunctionType;
}

interface UiTemplateBaseActionRoute {
  route: any[];
  options?: NavigationExtras;
}

export {
  UiTemplateBaseActionRoute,
  UiTemplateBaseActions,
  UiTemplateBaseInputs,
  UiTemplateBaseInterface,
  UiTemplateBaseLifecycleHooks,
  UiTemplateBaseState,
};
