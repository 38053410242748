import { UiHeaderMenuItem } from '@micro-ui/molecule';

import { UiTemplateStateKey } from './state.enum';

import { UiTemplateBaseAction } from '../../shared/model/page-template.enum';
import { UiTemplateBaseState } from '../../shared/model/page-template.interface';

//Not a mistake, we are exporting here
import { DeepPartial } from '@micro-core/utility';
export { DeepPartial } from '@micro-core/utility';

export interface UiTemplateState extends UiTemplateBaseState {
  [UiTemplateStateKey.CHILD_MODAL]?: { [key: string]: string | number | boolean | unknown };
  [UiTemplateStateKey.CTA_1]?: UiTemplatePageStateCta;
  [UiTemplateStateKey.CTA_2]?: UiTemplatePageStateCta;
  [UiTemplateStateKey.FOOTER_CHECKED]?: boolean;
  [UiTemplateStateKey.FORM]?: UiTemplatePageStateForm;
  [UiTemplateStateKey.HEADER_MENU]?: { hide: string[]; last: (UiHeaderMenuItem | undefined | null)[] };
}

export interface UiTemplatePageStateForm {
  dirty: boolean;
  lastUpdate: string;
  touched: boolean;
  valid: boolean;
  values: { [key: string]: string | number | boolean | unknown };
}

export interface UiTemplatePageStateCta {
  action?: UiTemplatePageStateAction;
  disabled: boolean;
  processing: boolean;
}

export interface UiTemplatePageStateAction {
  log: UiTemplatePageStateActionLog[];
  last: UiTemplatePageStateActionLog;
}

export interface UiTemplatePageStateActionLog {
  at: string;
  with: UiTemplateBaseAction;
}

export interface UiTemplatePageStateCondition {
  triggeringKeys: UiTemplateStateKey[];
  manualConditionRunner: (pendingState: UiTemplateState) => DeepPartial<UiTemplateState>;
}
