import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'brightside-web-financial-accounts-transfer-funds',
  templateUrl: './financial-accounts-transfer-funds.component.html',
  styleUrls: ['./financial-accounts-transfer-funds.component.css']
})
export class FinancialAccountsTransferFundsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
