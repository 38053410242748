import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaintenancePageComponent } from './maintenance-page/maintenance-page.component';
import { RouterModule, Routes } from '@angular/router';
import { BrightsideUiModule } from '@brightside/brightside-ui';
import { AuthGuard, OnboardingCompleteGuard, SharedDesktopModule } from '@brightside-web/shared/desktop';
import { MicroUiMoleculeModule } from '@micro-ui/molecule';
import { TranslateModule } from '@ngx-translate/core';
import { MaintenanceFeatureComponent } from './maintenance-feature/maintenance-feature.component';
import { LayoutStyle } from '@micro-core/utility';

const ROUTES: Routes = [
  {
    path: '',
    component: MaintenancePageComponent,
    pathMatch: 'full',
    data: {appDisplayStyle: LayoutStyle.FULL_SCREEN}
  },
  {
      path: 'feature',
      component: MaintenanceFeatureComponent,
      canActivate: [AuthGuard, OnboardingCompleteGuard]
  },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(ROUTES),
    BrightsideUiModule,
    SharedDesktopModule,
    MicroUiMoleculeModule,
    TranslateModule,
  ],
  declarations: [MaintenancePageComponent, MaintenanceFeatureComponent],
})
export class DesktopFeatureMaintenanceModule {}
